import React, { useMemo, memo } from 'react';
import { Row } from 'antd';
import { SvgIcon } from 'app/components/SvgIcon';
import { useSelector } from 'react-redux';
import { selectTransactionDetail } from 'store/slices/transaction';
import styles from './index.module.scss';
import RakkarStatusButton from 'app/components/common/RakkarStatusButton';
import { TransactionType, TravelRuleStatus } from 'enum/common';
import { isEmpty, some } from 'lodash';
import { formatTimestamp } from 'utils/date';
import { formatText } from 'utils/common';
import { PREFIX_API } from 'constants/index';
import TextInfor from 'app/components/common/TextInfor';

type Props = {};

type WithdrawProps = {
  trStatus: string;
  provider: string;
  rakDescription: string;
  screeningTime: string;
};

type DepositProps = {
  screening: {
    status: string;
    provider: string;
    frozenReason: string;
    screeningTime: string;
  };
  nameMatching: {
    status: string;
    provider: string;
    frozenReason: string;
    screeningTime: string;
  };
};

const travelRuleDepositMapping = tr => ({
  'tr.INCOMING.1': {
    screening: {
      status: tr?.trStatus?.toUpperCase(),
      provider: tr?.provider,
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: '',
      provider: '',
      frozenReason: '',
      screeningTime: '',
    },
  },
  'tr.INCOMING.2': {
    screening: {
      status: tr?.trStatus?.toUpperCase(),
      provider: tr?.provider,
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: '',
      provider: '',
      frozenReason: 'Pending for TR screening',
      screeningTime: '',
    },
  },
  'tr.INCOMING.3': {
    screening: {
      status: tr?.trStatus?.toUpperCase(),
      provider: tr?.provider,
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: '',
      provider: '',
      frozenReason: '',
      screeningTime: '',
    },
  },
  'tr.INCOMING.4': {
    screening: {
      status: TravelRuleStatus.ACCEPTED,
      provider: tr?.provider,
      frozenReason: '',
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: TravelRuleStatus.FROZEN,
      provider: '',
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
  },
  'tr.INCOMING.5': {
    screening: {
      status: tr?.trStatus?.toUpperCase(),
      provider: tr?.provider,
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: '',
      provider: '',
      frozenReason: '',
      screeningTime: '',
    },
  },
  'tr.INCOMING.6': {
    screening: {
      status: tr?.trStatus?.toUpperCase(),
      provider: tr?.provider,
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: '',
      provider: '',
      frozenReason: '',
      screeningTime: '',
    },
  },
  'tr.INCOMING.7': {
    screening: {
      status: TravelRuleStatus.ACCEPTED,
      provider: tr?.provider,
      frozenReason: '',
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: TravelRuleStatus.UNFROZEN,
      provider: '',
      frozenReason: tr?.rakDescription,
      screeningTime: tr?.screeningTime,
    },
  },
  'tr.INCOMING.8': {
    screening: {
      status: TravelRuleStatus.ACCEPTED,
      provider: tr?.provider,
      frozenReason: '',
      screeningTime: tr?.screeningTime,
    },
    nameMatching: {
      status: TravelRuleStatus.MATCHED,
      provider: '',
      frozenReason: '',
      screeningTime: tr?.screeningTime,
    },
  },
});

const Withdraw = memo((props: WithdrawProps) => (
  <>
    <Row style={{ margin: '20px 0' }}>
      <TextInfor
        label="Status"
        dataTestId="status"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            <RakkarStatusButton
              status={props?.trStatus?.toUpperCase()}
              template={TravelRuleStatus}
              notFound={'-'}
            />
          </Row>
        }
      />
      <TextInfor
        label="Frozen reason"
        dataTestId="frozen-reason"
        text={props?.rakDescription}
      />
    </Row>
    <Row>
      <TextInfor
        label="Provider"
        dataTestId="provider"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            {props?.provider ? (
              <>
                {' '}
                <span>{formatText(props?.provider)}</span>
                <SvgIcon
                  name="redirect"
                  width={13}
                  height={13}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(`${PREFIX_API.NOTABENE_APP_BASE_URL}/dashboard`)
                  }
                />
              </>
            ) : (
              '-'
            )}
          </Row>
        }
      />
      <TextInfor
        label="Screening time"
        dataTestId="screening-time"
        text={props?.screeningTime ? formatTimestamp(props.screeningTime) : '-'}
      />
    </Row>
  </>
));

const Deposit = memo((props: DepositProps) => (
  <>
    <h4 className={styles.titleTop}>Pre and Post-screenings</h4>
    <Row style={{ margin: '20px 0' }}>
      <TextInfor
        label="Status"
        dataTestId="status"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            <RakkarStatusButton
              status={props?.screening?.status?.toUpperCase()}
              template={TravelRuleStatus}
              notFound={'-'}
            />
          </Row>
        }
      />
      <TextInfor
        label="Frozen reason"
        dataTestId="frozen-reason"
        text={props?.screening?.frozenReason}
      />
    </Row>
    <Row>
      <TextInfor
        label="Provider"
        dataTestId="provider"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            {props?.screening?.provider ? (
              <>
                {' '}
                <span>{formatText(props?.screening?.provider)}</span>
                <SvgIcon
                  name="redirect"
                  width={13}
                  height={13}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(`${PREFIX_API.NOTABENE_APP_BASE_URL}/dashboard`)
                  }
                />
              </>
            ) : (
              '-'
            )}
          </Row>
        }
      />
      <TextInfor
        label="Screening time"
        dataTestId="screening-time"
        text={
          props?.screening?.screeningTime
            ? formatTimestamp(props?.screening?.screeningTime)
            : '-'
        }
      />
    </Row>
    <h4 className={styles.titleBottom}>Name matching</h4>
    <Row style={{ margin: '20px 0' }}>
      <TextInfor
        label="Status"
        dataTestId="status"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            <RakkarStatusButton
              status={props?.nameMatching?.status?.toUpperCase()}
              template={TravelRuleStatus}
              notFound={'-'}
            />
          </Row>
        }
      />
      <TextInfor
        label="Frozen reason"
        dataTestId="frozen-reason"
        text={props?.nameMatching?.frozenReason}
      />
    </Row>
    <Row>
      <TextInfor
        label="Provider"
        dataTestId="provider"
        text={
          <Row style={{ alignItems: 'center', gap: 10 }}>
            {props?.nameMatching?.provider ? (
              <>
                {' '}
                <span>{formatText(props?.nameMatching?.provider)}</span>
                <SvgIcon
                  name="redirect"
                  width={13}
                  height={13}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(`${PREFIX_API.NOTABENE_APP_BASE_URL}/dashboard`)
                  }
                />
              </>
            ) : (
              '-'
            )}
          </Row>
        }
      />
      <TextInfor
        label="Screening time"
        dataTestId="screening-time"
        text={
          props?.nameMatching?.screeningTime
            ? formatTimestamp(props?.nameMatching?.screeningTime)
            : '-'
        }
      />
    </Row>
  </>
));

const TravelRule = (props: Props) => {
  const transactionData = useSelector(selectTransactionDetail);

  const transactionDetail = useMemo(
    () => transactionData?.data || {},
    [transactionData],
  );

  const additionalData = useMemo(
    () => transactionDetail?.additionalData || {},
    [transactionDetail],
  );

  const isDeposit = useMemo(
    () => [TransactionType.INCOMING].includes(transactionDetail.type),
    [transactionDetail.type],
  );

  const depositData = useMemo(
    () =>
      travelRuleDepositMapping(additionalData)[additionalData?.trTypeObjKey] ||
      {},
    [additionalData],
  );

  const isDisplayNoApplicable = useMemo(() => {
    // TR txn in rakkar only, not send to Fb
    if (
      !transactionDetail?.fireblocksStatus &&
      transactionDetail.type === TransactionType.OUTGOING &&
      ['PENDING', 'REJECTED', 'CANCELLED'].includes(transactionDetail?.status)
    ) {
      return !transactionDetail?.vaspId;
    }

    return !transactionDetail?.additionalData?.trTypeObjKey;
  }, [transactionDetail]);

  if (isDisplayNoApplicable) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.icon}>
              <SvgIcon name="info-red" width={16} height={16} />
            </div>
            <div className={styles.title}>
              Travel rule
              <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                <RakkarStatusButton
                  status={'NOT_APPLICABLE'}
                  template={TravelRuleStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <SvgIcon name="info-red" width={16} height={16} />
          </div>
          <div className={styles.title}>Travel rule</div>
        </div>
      </div>
      {!isDeposit ? (
        <Withdraw {...additionalData} />
      ) : (
        <Deposit {...depositData} />
      )}
    </div>
  );
};

export default TravelRule;
