import { useMsal } from '@azure/msal-react';
import { Badge } from 'antd';
import { SvgIcon } from 'app/components/common/SvgIcon';
import { USER_ROLES } from 'enum/common';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  authActions,
  selectIsLoggedIn,
  selectRoles,
  selectUserProfile,
} from 'store/slices/auth';
import { commonActions, selectTotalRequest } from 'store/slices/common';
import { getDefaultAvatarInfo } from 'utils/common';
import styles from './index.module.scss';
import { PathList } from 'constants/index';
import clsx from 'clsx';
import ModalConfirmNavigation from 'app/components/EditCustomer/ModalConfirmNavigation';
import { editCustomerActions } from 'store/slices/editCustomer';
import { RakkarLogoIcon } from 'assets/components/icon';

interface MenuDTO {
  name: string;
  icon: string;
  fill?: string;
  path: string;
  children: {
    name: string;
    path: string;
  }[];
}

const auditLogMenu = {
  name: 'ADMINISTRATOR',
  icon: 'user',
  fill: '#8296DA',
  path: '',
  children: [
    {
      name: 'Audit log',
      path: '/audit-log',
    },
    {
      name: 'Access control',
      path: '/access-control',
    },
    {
      name: 'Wallet info',
      path: PathList.walletInfo,
    },
    {
      name: 'Currency',
      path: PathList.currency,
    },
    {
      name: 'Currency Convert',
      path: PathList.currencyConvert,
    },
    {
      name: PathList.cfg_sourceOfFund,
      path: PathList.cfg_sourceOfFund,
    },
    {
      name: PathList.cfg_typeOfCompany,
      path: PathList.cfg_typeOfCompany,
    },
    {
      name: PathList.cfg_country,
      path: PathList.cfg_country,
    },
    {
      name: PathList.BatchElvenTxnRAKFB,
      path: PathList.BatchElvenTxnRAKFB,
    },
    {
      name: PathList.BatchElvenTxnRAKOnChain,
      path: PathList.BatchElvenTxnRAKOnChain,
    },
    {
      name: PathList.ReportType,
      path: PathList.ReportType,
    },
    {
      name: PathList.ExportType,
      path: PathList.ExportType,
    },
    {
      name: PathList.TravelRuleVASP,
      path: PathList.TravelRuleVASP,
    },
    {
      name: PathList.TravelRuleJurisdictions,
      path: PathList.TravelRuleJurisdictions,
    },
  ],
};

const securityMenu = {
  name: 'ADMINISTRATOR',
  icon: 'user',
  fill: '#8296DA',
  path: '',
  children: [
    {
      name: 'Audit log',
      path: '/audit-log',
    },
  ],
};

const menusByRole = [
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Customer management',
            path: '/customer-management',
          },
          {
            name: 'Request',
            path: '/pending-request',
          },
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
          {
            name: 'Billing',
            path: '/billing',
          },
        ],
      },
    ],
    name: USER_ROLES.ADMIN,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Customer management',
            path: '/customer-management',
          },
          {
            name: 'Request',
            path: '/pending-request',
          },
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
        ],
      },
    ],
    name: USER_ROLES.COMPLIANCE,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Customer management',
            path: '/customer-management',
          },
          {
            name: 'Request',
            path: '/pending-request',
          },
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
          {
            name: 'Billing',
            path: '/billing',
          },
          {
            name: 'Audit log',
            path: '/audit-log',
          },
        ],
      },
    ],
    name: USER_ROLES.OPERATION,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Customer management',
            path: '/customer-management',
          },
          {
            name: 'Request',
            path: '/pending-request',
          },
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
          {
            name: 'Billing',
            path: '/billing',
          },
        ],
      },
    ],
    name: USER_ROLES.CUSTOMER_SUCCESS,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Billing',
            path: '/billing',
          },
        ],
      },
    ],
    name: USER_ROLES.FINANCE,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Request',
            path: '/pending-request',
          },
        ],
      },
      auditLogMenu,
    ],
    name: USER_ROLES.PRODUCT,
  },
  {
    menus: [securityMenu],
    name: USER_ROLES.SECURITY,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Request',
            path: '/pending-request',
          },
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
        ],
      },
      auditLogMenu,
    ],
    name: USER_ROLES.PRODUCT_WITH_REVIEW_TRANSACTION,
  },
  {
    menus: [
      {
        name: 'Customer',
        icon: 'vault',
        path: '',
        children: [
          {
            name: 'Transaction monitoring',
            path: '/transaction',
          },
        ],
      },
      securityMenu,
    ],
    name: USER_ROLES.SECURITY_WITH_REVIEW_TRANSACTION,
  },
];

const ProtectedLayout = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const [current, setCurrent] = useState(location.pathname);
  const [targetPath, setTargetPath] = useState<string>('');
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const roles = useAppSelector(selectRoles) ?? [];
  const navigate = useNavigate();
  const profile = useAppSelector(selectUserProfile);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get('id');

  const total =
    useAppSelector(selectTotalRequest)?.data?.totalPendingRequest || 0;

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/login',
    });
    dispatch(authActions.logout());
  };

  const handleCheckNavigattion = (path: string) => {
    if (customerId && location.pathname === '/customer-management') {
      dispatch(editCustomerActions.setIsOpenNavigation(true));
      dispatch(editCustomerActions.setNavigationPath(path));
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    dispatch(authActions.getPermissionsRequest());
    dispatch(authActions.getUserProfileRequest());
    dispatch(commonActions.getTotalRequestRequest());
  }, [dispatch]);

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  useEffect(() => {
    if (isLoggedIn && current === '/') {
      navigate('/customer-management');
    }
  }, [current, isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const renderMenu = () => {
    let menus: MenuDTO[];

    const roleNames = roles.map(role => role.name);

    if (roles[0]?.name === USER_ROLES.PRODUCT) {
      if (roles[1]?.name === USER_ROLES.REVIEW_TRANSACTION) {
        menus =
          menusByRole.find(
            role => role.name === USER_ROLES.PRODUCT_WITH_REVIEW_TRANSACTION,
          )?.menus ?? [];
      } else {
        menus =
          menusByRole.find(role => role.name === roles[0]?.name)?.menus ?? [];
      }
    } else if (roles[0]?.name === USER_ROLES.SECURITY) {
      if (roles[1]?.name === USER_ROLES.REVIEW_TRANSACTION) {
        menus =
          menusByRole.find(
            role => role.name === USER_ROLES.SECURITY_WITH_REVIEW_TRANSACTION,
          )?.menus ?? [];
      } else {
        menus =
          menusByRole.find(role => role.name === roles[0]?.name)?.menus ?? [];
      }
    } else {
      menus =
        menusByRole.find(role => role.name === roles[0]?.name)?.menus ?? [];
    }

    if (roleNames.includes(USER_ROLES.PRODUCT)) {
      const customerMenuIndex = (
        menus as typeof menusByRole[number]['menus']
      ).findIndex(menu => menu.name === 'Customer');

      menus[customerMenuIndex].children.some(
        child => child.path === '/wallet-connect',
      ) ||
        menus[customerMenuIndex].children.push({
          name: 'Rakkar Connect',
          path: '/wallet-connect',
        });
    }

    return (
      <>
        {menus.map((menu: MenuDTO, indexMenu) => {
          const { fill = '#e25ab3' } = menu;

          return (
            <div
              className={styles.menuContainer}
              key={`${menu.name}-${indexMenu}`}
            >
              <div className={styles.menu}>
                <SvgIcon
                  name={menu.icon}
                  fill={fill}
                  width={20}
                  height={20}
                  className={styles.menu__icon}
                />
                <div className={styles.menu__name}>{menu.name}</div>
              </div>
              {menu?.children.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.itemMenu} ${
                      current === item.path ? styles['itemMenu--active'] : ''
                    }`}
                    onClick={() => handleCheckNavigattion(item.path)}
                  >
                    <div className={styles.itemMenu__name}>{item.name}</div>
                    {item.name === 'Request' && (
                      <div className={styles.itemMenu__badge}>
                        <Badge count={total} overflowCount={99}></Badge>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <div className={styles['fixed-top']}>
          <div className={styles.logo}>
            <RakkarLogoIcon width={116} height={24} />
          </div>
          <div className={styles['menu-content']}>{renderMenu()}</div>
          <div className={styles.bottom}>
            <div className={styles.profile}>
              <div className={styles.profile__avatar}>
                {profile?.picture ? (
                  <img src={profile.picture} alt="" data-testid="avatar" />
                ) : (
                  <div
                    className={styles['profile__avatar--default']}
                    data-testid="avatar-default"
                  >
                    {getDefaultAvatarInfo(profile?.name || '')}
                  </div>
                )}
              </div>

              <div className={styles.profile__content}>
                <div
                  className={styles['profile__content--name']}
                  data-testid="profile-name"
                >
                  {profile?.name}
                </div>
                <div
                  className={styles['profile__content--role']}
                  data-testid="profile-role"
                >
                  {roles[0]?.name}
                </div>
              </div>
            </div>
            <div className={styles.logout}>
              <div className={styles.divLogout}>
                <SvgIcon name="logout" fill="#A8B8EE" width={16} height={16} />
                <div className={styles.logout__text} onClick={handleLogout}>
                  Log out
                </div>
              </div>
              <SvgIcon
                name="headphone"
                fill="#A8B8EE"
                stroke="#A8B8EE"
                width={16}
                height={16}
              />
              <SvgIcon name="setting" fill="#A8B8EE" width={16} height={16} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(styles.right, {
          [styles.edit__customer]:
            customerId && location.pathname === '/customer-management',
        })}
      >
        <Outlet />
      </div>
      {customerId && location.pathname === '/customer-management' && (
        <ModalConfirmNavigation />
      )}
    </div>
  );
};

export default ProtectedLayout;
